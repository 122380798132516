import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SailingIcon from '@mui/icons-material/Sailing';
import PublicIcon from '@mui/icons-material/Public';
import AnchorIcon from '@mui/icons-material/Anchor';
import EmojiFlagsTwoToneIcon from '@mui/icons-material/EmojiFlagsTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';
import i18n from 'i18next';
import { Typography } from '@mui/material';

function normalizeString(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

export function CountrySelect({ selectedCountries, setSelectedCountries }) {
  const options = [...countries];
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderFlag = (code) => (
    <LazyLoadImage
      alt="flag"
      src={`/w20/${code.toLowerCase()}.png`}
      effect="blur"
      width={20}
      margin={10}
      wrapperClassName="flag-wrapper"
    />
  );

  const handleChange = (event, newValue) => {
    setSelectedCountries(newValue);
    if (newValue.length > 0) {
      const selectedCountry = newValue[newValue.length - 1];
      if (selectedCountry.type === 'country') {
        const countryName = selectedCountry.name.toLowerCase().replace(/\s+/g, '-');
        navigate(`/country/${countryName}`);
      }
    }
  };

  return (
    <Box id="mPicker">
      <Box sx={{marginBottom: 2}}>
        <Typography >
            <EmojiFlagsTwoToneIcon sx={{color: "#cce1f5", fontSize: '40px'}} />
        </Typography>
      
      <Typography variant='h5' sx={{color: "#cce1f5"}}>
        
        <h1>{t("Rent a yacht")}</h1>
      </Typography>
      <Typography sx={{ color: "#FFF", fontSize: 12, marginTop: 2 }}>
          <h3>
            <SailingIcon style={{ verticalAlign: 'middle', marginBottom: '5px', fontSize: 15 }} /> 8263 {t("boats!")}
            <AnchorIcon style={{ verticalAlign: 'middle', marginBottom: '5px', marginLeft: '20px', fontSize: 15 }} /> 305  {t("marinas")}
            <PublicIcon style={{ verticalAlign: 'middle', marginBottom: '5px', marginLeft: '20px' , fontSize: 15}} /> 36  {t("countries")}
          </h3>
        </Typography>
      </Box>
      <Autocomplete
  className="mui-autocomplete-focused"
  sx={{
    width: {
      xs: '90vw', // 90% width on mobile devices
      sm: '90vw', // 90% width on tablets
      lg: '39vw',
      xl: '39vw',
      md: '39vw', // 600px width on large devices
    },
    minWidth: 300,
    '& .MuiAutocomplete-popupIndicator': {
      color: '#D8D9DA',
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: '#D8D9DA',
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: '#F0F4F8', // Pastel background for options
      '&[data-focus="true"]': {
        backgroundColor: '#E3E7EB',
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#C9D6DF',
      },
    },
    '& .MuiAutocomplete-listbox': {
      backgroundColor: '#F0F4F8', // Pastel background for listbox
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
  }}
  multiple
  id="tags-outlined"
  options={options}
  getOptionLabel={(option) => t(option.name)}
  onChange={handleChange}
  filterSelectedOptions
  filterOptions={(options, { inputValue }) => {
    const normalizedInput = normalizeString(inputValue);
    return options.filter(option => {
      let optionLabel = t(option.name);
      const normalizedOption = normalizeString(optionLabel);
      return normalizedOption.includes(normalizedInput);
    });
  }}
  renderOption={(props, option) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.type + option.name} gap={1.5}>
      {option.code && renderFlag(option.code)}
      {t(option.name)}
    </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
  
      placeholder={t("Country")}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiInputBase-root": {
          backgroundColor: "#cce6ff", // Light pastel background
          borderRadius: "8px", // Rounded corners
          '& fieldset': {
            borderColor: 'transparent', // No border
          },
          '&:hover fieldset': {
            borderColor: 'transparent', // No border on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: 'transparent', // No border when focused
          },
        },
        "& .MuiInputLabel-root": {
          color: "#000", // Matching label color
        },
        "& .MuiInputBase-input": {
          color: "#000", // Matching input text color
        },
      }}
    />
  )}
/>

</Box>
  );
}

const countries = [
  { code: 'HR', name: 'Croatia', value: 1, type: 'country' },
  { code: 'GR', name: 'Greece', value: 100116, type: 'country' },
  { code: 'TR', name: 'Turkey', value: 100181, type: 'country' },
  { code: 'IT', name: 'Italy', value: 100147, type: 'country' },
  { code: 'ES', name: 'Spain', value: 100119, type: 'country' },
  { code: 'NO', name: 'Norway', value: 100156, type: 'country' },
  { code: 'VG', name: 'British Virgin Islands', value: 126446, type: 'country' },
  { code: 'FR', name: 'France', value: 100118, type: 'country' },
  { code: 'SC', name: 'Seychelles', value: 124874, type: 'country' },
  { code: 'TH', name: 'Thailand', value: 100174, type: 'country' },
  { code: 'SI', name: 'Slovenia', value: 100144, type: 'country' },
  { code: 'VI', name: 'U.S. Virgin Islands', value: 1222032, type: 'country' },
  { code: 'BS', name: 'Bahamas', value: 491658, type: 'country' },
  { code: 'MT', name: 'Malta', value: 100126, type: 'country' },
  { code: 'ME', name: 'Montenegro', value: 100143, type: 'country' },
  { code: 'SE', name: 'Sweden', value: 100155, type: 'country' },
  { code: 'PF', name: 'French Polynesia', value: 842497, type: 'country' },
  { code: 'BQ', name: 'Caribbean', value: 485571, type: 'country' },
];
