import { LicenseInfo } from '@mui/x-license-pro';
//cookies
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import Link from '@mui/material/Link';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EuroSymbolTwoToneIcon from '@mui/icons-material/EuroSymbolTwoTone';
import { useState } from 'react';
import { useEffect } from 'react';
import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';  // For tooltip

import AnchorIcon from '@mui/icons-material/Anchor';
import PublicIcon from '@mui/icons-material/Public';

import { Modal } from '@mui/material';

import { LineChart } from '@mui/x-charts/LineChart';

import DirectionsBoatFilledTwoToneIcon from '@mui/icons-material/DirectionsBoatFilledTwoTone';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//import ImageList from '@mui/material/ImageList';
//import ImageListItem from '@mui/material/ImageListItem';
//import ImageListItemBar from '@mui/material/ImageListItemBar';
//import ListSubheader from '@mui/material/ListSubheader';
//import IconButton from '@mui/material/IconButton';
//import InfoIcon from '@mui/icons-material/Info';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from "axios";
import './App.css';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { CountrySelect } from './content/countrySelect';
import { LandingPage } from './content/LandingPage';
import { LastView } from './content/LastView';
import { CharterGrid } from './content/CharterGrid';
import { Mansonry } from './content/mansonry';
import { BlogWidget } from './content/blogWidget';
import { Footer } from './content/footer';
import { BoatTypeSelect } from './content/boatTypeSelect';
import { DateRangeSelect } from './content/dateRangeSelect';
import { BoatCard } from './content/boatCard';
import { RangeCabins } from './content/RangeCabins';
import { RangeLength } from './content/RangeLength';
import { RangePrice } from './content/RangePrice';
import { RangePerson } from './content/RangePerson';
import { RangeYear } from './content/RangeYear';
import { RangeHeads } from './content/RangeHeads';
import { ManufacturerSelect } from './content/ManufacturerSelect';
import { CompanySelect } from './content/CompanySelect';
import { EquipSelect } from './content/EquipSelect';
import { SailTypeSelect } from './content/SailTypeSelect';

import BoatDetails from './content/BoatDetails';
import BoatDetailsNoDate from './content/BoatDetailsNoDate';
import Country from './content/Country';
import Resume from './content/Resume';
import Thewall from './content/Thewall';
import Bareboat from './content/bareboatCharter';
import Profile from './content/profile';
import Favoritesweb from './content/Favoritesweb';
import Navbar from './content/Navbar';
import NavbarDetail from './content/NavbarDetail';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SailingIcon from '@mui/icons-material/Sailing';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
//import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import Avatar from '@mui/material/Avatar';


import './content/i18n';
//translate
import { useTranslation } from 'react-i18next';


//drawer
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import { Hidden } from '@mui/material';
//import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Chip from '@mui/material/Chip';
import { CalendarIcon } from '@mui/x-date-pickers';
import { CalendarTodayTwoTone } from '@mui/icons-material';

import CreditCardIcon from '@mui/icons-material/CreditCard';



const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '90%',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


function App() {
  const { t } = useTranslation();
  //google API user 
  //const [email, setEmail] = useState('peteragh2@gmail.com'); // daj prec email 
  const [email, setEmail] = useState(''); // daj prec email 
  const [userNameCookie, setUserName] = useState('');
  const [userPictureCookie, setUserPicture] = useState('');
  const [totalBoats, setTotalBoats] = useState(0);

  const [open, setOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);


  const handleOpen = (booking) => {
    setSelectedBooking(booking);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);



  function getCookie(name) {

    const value = `; ${document.cookie}`;

    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const cookieValue = parts.pop().split(';').shift();

      return cookieValue;
    } else {
      console.log(`Cookie ${name} not found`); // This will log if the specific cookie isn't found
    }
  }

  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: 'cloud',
          position: 'bottom center',
          flipButtons: false,
          equalWeightButtons: true
        },
        preferencesModal: {
          layout: 'box',
          // position: 'left right',
          flipButtons: false,
          equalWeightButtons: true
        }
      },
      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {}
      },

      language: {
        default: 'sk',
        autoDetect: 'browser',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description: 'To enhance your experience, our website uses cookies. Cookies are small text files stored on your device that help us improve our services and provide you with a more tailored experience.<br><br>How We Use Cookies:<br> Login Authentication Cookies are essential for verifying your identity and maintaining your session when you log in to our website. They ensure a secure and seamless experience.<br><br>Google Analytics:<br> We use Google Analytics cookies to understand how you interact with our website. These cookies help us gather insights into user behavior, allowing us to improve our website and services.',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Somebody said ... cookies?',
                  description: 'I want one!'
                },

                {
                  title: 'Strictly Necessary cookies',
                  description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Performance and Analytics',
                  description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'More information',
                  description: 'For any queries in relation to my policy on cookies and your choices, please info@namornici.sk'
                }
              ]
            }
          },

          sk: {
            consentModal: {
              title: 'Používame cookies',
              description: 'Aby sme zlepšili váš zážitok, naša webová stránka používa cookies. Cookies sú malé textové súbory uložené na vašom zariadení, ktoré nám pomáhajú zlepšovať naše služby a poskytovať vám prispôsobenejší zážitok.<br><br>Ako používame cookies:<br> Prihlasovacie autentifikačné cookies sú nevyhnutné na overenie vašej totožnosti a udržanie vašej relácie pri prihlásení na našu webovú stránku. Zabezpečujú bezpečný a plynulý zážitok.<br><br>Google Analytics:<br> Používame cookies Google Analytics na pochopenie, ako interagujete s našou webovou stránkou. Tieto cookies nám pomáhajú zhromažďovať informácie o správaní používateľov, čo nám umožňuje zlepšovať našu webovú stránku a služby.',
              acceptAllBtn: 'Akceptovať všetky',
              acceptNecessaryBtn: 'Odmietnuť',
              showPreferencesBtn: 'Vlastné preferencie'
            },
            preferencesModal: {
              title: 'Spravuj vlastné preferencie',
              acceptAllBtn: 'Akceptovať všetky',
              acceptNecessaryBtn: 'Odmietnuť',
              savePreferencesBtn: 'Akceptovať vzbrané',
              closeIconLabel: 'Zavrieť',
              sections: [
                {
                  title: 'Povedal niekto koláčiky?',
                  description: 'Dal by som si!'
                },

                {
                  title: 'Potrebné cookies',
                  description: 'Tieto cookies potrebujeme na to aby stránka fungovala správne, nepoužívame ich na marketing ani neposkytujeme nikomu ďalšiemu.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Analýza',
                  description: 'Tieto cookies používame aby sme vedeli koľko ľudí a z kadial navštívi náš web aby sme vedeli upravovať náš obsah na webe.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'Viac informácií',
                  description: 'Pre akékoľvek informácie náš prosím kontaktujte info@namornici.sk'
                }
              ]
            }
          }
        }
      }
    });
  }, []);



  useEffect(() => {
    const fetchedEmail = getCookie('email');
    if (fetchedEmail) {
      setEmail(fetchedEmail);
    }
    const fetchedUserName = getCookie('userNameCookie');
    if (fetchedUserName) {
      setUserName(fetchedUserName);
    }
    const fetchedUserPicture = getCookie('userPictureCookie');
    if (fetchedUserPicture) {
      setUserPicture(fetchedUserPicture);
    }

  }, []);
  //google API user 

  //drawer
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 280 }}
      role="presentation"
    // Remove onClick event handler here if you don't want the drawer to close on any click
    //onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>

          <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Box>
              <p>{t("Hooray! Available")} <SailingIcon style={{}} /> <span class='span-color' > {dajLicePocet()}</span>  {t("boats!")}  </p>
            </Box>


          </Stack>
        </ListItem>

        <ListItem >
          <RangePrice value={priceRange} onChange={handlePriceRangeChange} minPrice={minPrice} maxPrice={maxPrice} minPriceInit={minPriceInit} maxPriceInit={maxPriceInit} />
        </ListItem>
        <ListItem >
          <RangeCabins value={cabinRange} onChange={handleCabinRangeChange} minCabin={minCabin} maxCabin={maxCabin} minCabinInit={minCabinInit} maxCabinInit={maxCabinInit} />
        </ListItem>
        <ListItem >
          <RangeLength value={lengthRange} onChange={handleLengthRangeChange} minLength={minLength} maxLength={maxLength} minLengthInit={minLengthInit} maxLengthInit={maxLengthInit} />
        </ListItem>
        <ListItem>
          <RangePerson value={personRange} onChange={handlePersonRangeChange} minPerson={minPerson} maxPerson={maxPerson} minPersonInit={minPersonInit} maxPersonInit={maxPersonInit} />
        </ListItem>
        <ListItem>
          <RangeYear value={yearRange} onChange={handleYearRangeChange} minYear={minYear} maxYear={maxYear} minYearInit={minYearInit} maxYearInit={maxYearInit} />
        </ListItem>
        <ListItem>
          <RangeHeads value={headsRange} onChange={handleHeadsRangeChange} minHeads={minHeads} maxHeads={maxHeads} minHeadsInit={minHeadsInit} maxHeadsInit={maxHeadsInit} />
        </ListItem>
        <ListItem>
          <ManufacturerSelect selectedManufacturer={selectedManufacturer} setSelectedManufacturer={setSelectedManufacturer} />
        </ListItem>
        <ListItem>
          <CompanySelect selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} />
        </ListItem>
        <ListItem>
          <EquipSelect selectedEquip={selectedEquip} setSelectedEquip={setSelectedEquip} />
        </ListItem>
        <ListItem >
          <SailTypeSelect selectedSail={selectedSail} setSelectedSail={setSelectedSail} />
        </ListItem>
        <ListItem >

          <Box sx={{ display: 'flex' }}>
            <FormControlLabel
              control={<Checkbox checked={filterTypes.BAREBOAT} onChange={handleCheckboxChange} name="BAREBOAT" />}
              label={t("Bareboat")}
            />

            <FormControlLabel
              control={<Checkbox checked={filterTypes.CREWED} onChange={handleCheckboxChange} name="CREWED" />}
              label={t("Crewed")}
            />

          </Box>
        </ListItem>
        <br></br><br></br>
        <ListItem >
          <Button id="cSlider" color="info" variant="outlined" onClick={resetFilters}>{t("reset filters")} </Button>
        </ListItem>
        <ListItem>
          <Button
            id="cSlider"
            color="warning"
            variant="outlined"
            onClick={toggleDrawer(anchor, false)} // Use this to close the drawer
          >
            {t("OK")}
          </Button>
        </ListItem>
      </List>
    </Box>
  );


  //drawer end

  const [profileData, setProfileData] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedBoatType, setSelectedBoatType] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedEquip, setSelectedEquip] = useState([]);
  const [selectedSail, setSelectedSail] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputValueSail, setInputValueSail] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState('price');
  const [sortOrder, setSortOrder] = useState('asc'); // For price
  const [sortOrderByYear, setSortOrderByYear] = useState('asc'); // For year
  const [displayedItems, setDisplayedItems] = useState(10);
  const [filterTypes, setFilterTypes] = useState({
    BAREBOAT: true,
    CREWED: true
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFilterTypes(prevState => ({
      ...prevState,
      [name]: checked
    }));
  };

  //price slider
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [minPriceInit, setMinPriceInit] = useState(0);
  const [maxPriceInit, setMaxPriceInit] = useState(10000);
  const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);

  //cabins slider
  const [minCabin, setMinCabin] = useState(1);
  const [maxCabin, setMaxCabin] = useState(10);
  const [minCabinInit, setMinCabinInit] = useState(1);
  const [maxCabinInit, setMaxCabinInit] = useState(10);
  const [cabinRange, setCabinRange] = useState([minCabin, maxCabin]);


  // Added range state for length
  const [minLength, setMinLength] = useState(1);
  const [maxLength, setMaxLength] = useState(10);
  const [minLengthInit, setMinLengthInit] = useState(1);
  const [maxLengthInit, setMaxLengthInit] = useState(10);
  const [lengthRange, setLengthRange] = useState([minLength, maxLength]);

  // Added range state berths
  const [minPerson, setMinPerson] = useState(0);
  const [maxPerson, setMaxPerson] = useState(100);
  const [minPersonInit, setMinPersonInit] = useState(0);
  const [maxPersonInit, setMaxPersonInit] = useState(100);
  const [personRange, setPersonRange] = useState([minPerson, maxPerson]);

  // Added range state year
  const [minYear, setMinYear] = useState(1900);
  const [maxYear, setMaxYear] = useState(2200);
  const [minYearInit, setMinYearInit] = useState(1900);
  const [maxYearInit, setMaxYearInit] = useState(2200);
  const [yearRange, setYearRange] = useState([minYear, maxYear]);


  // Added range heads
  const [minHeads, setMinHeads] = useState(0);
  const [maxHeads, setMaxHeads] = useState(20);
  const [minHeadsInit, setMinHeadsInit] = useState(0);
  const [maxHeadsInit, setMaxHeadsInit] = useState(20);
  const [headsRange, setHeadsRange] = useState([minHeads, maxHeads]);



  function resetFilters() {
    setCabinRange([minCabin, maxCabin]);
    setLengthRange([minLength, maxLength]);
    setPriceRange([minPrice, maxPrice]);
    setPersonRange([minPerson, maxPerson]);
    setYearRange([minYear, maxYear]);
    setHeadsRange([minHeads, maxHeads]);
    setSelectedManufacturer([]);
    setSelectedCompany([]);
    setSelectedEquip([]);
    setSelectedSail([]);
    setInputValue("");
    setInputValueSail("");
    setDisplayedItems(10);
    setFilterTypes({
      BAREBOAT: true,
      CREWED: true
    });
  }

  const [totalFilteredBoats, setTotalFilteredBoats] = useState(0);

  function getData() {
    setIsLoading(true);
    setDisplayedItems(10);



    const startDate = dateRange[0];
    const endDate = dateRange[1];
    const countryIds = selectedCountries.map(country => country.value);
    const typeOfBoats = selectedBoatType.map(boatType => boatType.value);
    const locationIds = selectedCountries.filter(item => item.type === 'city').map(item => item.location_id);
    const regionIds = selectedCountries.filter(item => item.type === 'region').map(item => item.region_id);

    axios({
      method: "GET",
      url: "/api",
      params: {
        startDate: startDate,
        endDate: endDate,
        countryId: countryIds,
        typeOfBoatx: typeOfBoats,
        locationId: locationIds,
        regionId: regionIds,
        _: new Date().getTime()
      }
    })
      .then((response) => {
        let yachts = []
        yachts = Object.values(response.data).map(yacht => ({
          id: yacht.id,
          boatPicture: yacht.boatPicture,
          boatName: yacht.boatName,
          price: yacht.price,
          deposit: yacht.deposit,
          buildYear: yacht.buildYear,
          yachtModelName: yacht.yachtModelName,
          location: yacht.location,
          cabins: yacht.cabins,
          draft: yacht.draft,
          length: yacht.length,
          wc: yacht.wc,
          lengthMeters: yacht.lengthMeters,
          person: yacht.berths,
          manufacturer: yacht.manufacturer,
          companyId: yacht.company_id,
          sailTypeId: yacht.sailTypeId,
          charterType: yacht.charterType,
          country: yacht.country,
          typeOfBoat: yacht.typeOfBoat,
          company_name: yacht.company_name,
          maxDiscount: yacht.maxDiscount,
          berths: yacht.berths,
          equipments: yacht.equipments,
          lat: yacht.lat,
          lon: yacht.lon,
          listPrice: yacht.listPrice
        }));
        console.log(yachts)


        // Find min and max prices from all yachts
        const minPriceFromData = Math.min(...yachts.map(yacht => yacht.price));
        const maxPriceFromData = Math.max(...yachts.map(yacht => yacht.price));
        setMinPrice(minPriceFromData);
        setMaxPrice(maxPriceFromData);
        setMinPriceInit(minPriceFromData);
        setMaxPriceInit(maxPriceFromData);
        setPriceRange([minPriceFromData, maxPriceFromData]);

        //Cabins
        const minCabinFromData = Math.min(...yachts.map(yacht => yacht.cabins));
        const maxCabinFromData = Math.max(...yachts.map(yacht => yacht.cabins));

        setMinCabin(minCabinFromData);
        setMaxCabin(maxCabinFromData);
        setMinCabinInit(minCabinFromData);
        setMaxCabinInit(maxCabinFromData);
        setCabinRange([minCabinFromData, maxCabinFromData]);

        //Length
        const minLengthFromData = Math.min(...yachts.map(yacht => yacht.length));
        const maxLengthFromData = Math.max(...yachts.map(yacht => yacht.length));

        setMinLength(minLengthFromData);
        setMaxLength(maxLengthFromData);
        setMinLengthInit(minLengthFromData);
        setMaxLengthInit(maxLengthFromData);
        setLengthRange([minLengthFromData, maxLengthFromData]);

        //Berths / persons
        const minPersonFromData = Math.min(...yachts.map(yacht => yacht.berths));
        const maxPersonFromData = Math.max(...yachts.map(yacht => yacht.berths));

        setMinPerson(minPersonFromData);
        setMaxPerson(maxPersonFromData);
        setMinPersonInit(minPersonFromData);
        setMaxPersonInit(maxPersonFromData);
        setPersonRange([minPersonFromData, maxPersonFromData]);

        //Year
        const minYearFromData = Math.min(...yachts.map(yacht => yacht.buildYear));
        const maxYearFromData = Math.max(...yachts.map(yacht => yacht.buildYear));

        setMinYear(minYearFromData);
        setMaxYear(maxYearFromData);
        setMinYearInit(minYearFromData);
        setMaxYearInit(maxYearFromData);
        setYearRange([minYearFromData, maxYearFromData]);

        //Berths / persons
        const minHeadsFromData = Math.min(...yachts.map(yacht => yacht.wc));
        const maxHeadsFromData = Math.max(...yachts.map(yacht => yacht.wc));

        setMinHeads(minHeadsFromData);
        setMaxHeads(maxHeadsFromData);
        setMinHeadsInit(minHeadsFromData);
        setMaxHeadsInit(maxHeadsFromData);
        setHeadsRange([minHeadsFromData, maxHeadsFromData]);


        setProfileData(yachts);
        setTotalFilteredBoats(yachts.length);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }

  LicenseInfo.setLicenseKey('17a7062c331f0ab38c08b39f574650f4Tz04MDc0NSxFPTE3MzQ0MDg2MTAwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


  function dajLicePocet() {

    if (!profileData) return [];
    let sortedData = [...profileData];
    // Filter by cabins and length
    const manufacturersMap = selectedManufacturer.map(manF => manF.value);
    const companyMap = selectedCompany.map(comF => comF.title);
    const equipMap = selectedEquip.map(equipF => equipF.value);

    const sailMap = selectedSail.map(sailF => sailF.value);


    sortedData = sortedData.filter(yacht =>
      yacht.cabins >= cabinRange[0] && yacht.cabins <= cabinRange[1]
      && yacht.length >= lengthRange[0] && yacht.length <= lengthRange[1]
      && yacht.person >= personRange[0] && yacht.person <= personRange[1]
      && yacht.buildYear >= yearRange[0] && yacht.buildYear <= yearRange[1]
      && yacht.wc >= headsRange[0] && yacht.wc <= headsRange[1]
      && yacht.price >= priceRange[0] && (priceRange[1] === 10000 ? true : yacht.price <= priceRange[1])
    );

    profileData.forEach(yacht => {
      if (manufacturersMap && manufacturersMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          manufacturersMap.includes(yacht.manufacturer)

        );

      }

      if (companyMap && companyMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          companyMap.includes(yacht.company_name)

        );

      }


      if (sailMap && sailMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          sailMap.includes(yacht.sailTypeId)

        );

      }


      if (equipMap && equipMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          equipMap.every(equipment => yacht.equipments.includes(equipment))
        );
      }

      const checkedFilters = Object.keys(filterTypes).filter(key => filterTypes[key]);

      sortedData = sortedData.filter(yacht =>
        checkedFilters.includes(yacht.charterType)
      );
    });
    if (sortBy === 'price') {
      sortedData.sort((a, b) => sortOrder === 'asc'
        ? parseFloat(a.price) - parseFloat(b.price)
        : parseFloat(b.price) - parseFloat(a.price)
      );
    } else if (sortBy === 'year') {
      sortedData.sort((a, b) => sortOrderByYear === 'asc'
        ? a.buildYear - b.buildYear
        : b.buildYear - a.buildYear
      );
    }

    return sortedData.length;
  }


  function getSortedData() {

    if (!profileData) return [];
    let sortedData = [...profileData];
    // Filter by cabins and length
    const manufacturersMap = selectedManufacturer.map(manF => manF.value);
    const companyMap = selectedCompany.map(comF => comF.title);
    const equipMap = selectedEquip.map(equipF => equipF.value);
    const sailMap = selectedSail.map(sailF => sailF.value);


    sortedData = sortedData.filter(yacht =>
      yacht.cabins >= cabinRange[0] && yacht.cabins <= cabinRange[1]
      && yacht.length >= lengthRange[0] && yacht.length <= lengthRange[1]
      && yacht.person >= personRange[0] && yacht.person <= personRange[1]
      && yacht.buildYear >= yearRange[0] && yacht.buildYear <= yearRange[1]
      && yacht.wc >= headsRange[0] && yacht.wc <= headsRange[1]
      && yacht.price >= priceRange[0] && (priceRange[1] === 10000 ? true : yacht.price <= priceRange[1])
    );

    profileData.forEach(yacht => {
      if (manufacturersMap && manufacturersMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          manufacturersMap.includes(yacht.manufacturer)

        );

      }

      if (companyMap && companyMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          companyMap.includes(yacht.company_name)

        );

      }

      if (sailMap && sailMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          sailMap.includes(yacht.sailTypeId)

        );

      }

      if (equipMap && equipMap.length > 0) {
        sortedData = sortedData.filter(yacht =>
          equipMap.every(equipment => yacht.equipments.includes(equipment))
        );
      }


      console.log("TUTO EQUIP" + sailMap)
      const checkedFilters = Object.keys(filterTypes).filter(key => filterTypes[key]);

      sortedData = sortedData.filter(yacht =>
        checkedFilters.includes(yacht.charterType)
      );
    });
    if (sortBy === 'price') {
      sortedData.sort((a, b) => sortOrder === 'asc'
        ? parseFloat(a.price) - parseFloat(b.price)
        : parseFloat(b.price) - parseFloat(a.price)
      );
    } else if (sortBy === 'year') {
      sortedData.sort((a, b) => sortOrderByYear === 'asc'
        ? a.buildYear - b.buildYear
        : b.buildYear - a.buildYear
      );
    }

    return sortedData.slice(0, displayedItems);
  }

  const loadMoreItems = () => {
    // Logic to load more items
    setDisplayedItems(prev => prev + 10);
  };

  const handleLengthRangeChange = (newValue) => {
    setLengthRange(newValue);
  };

  const checkScrollPosition = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) return;
    loadMoreItems();
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);

  }, []);

  const handlePersonRangeChange = (newValue) => {
    setPersonRange(newValue);
  };

  const handleYearRangeChange = (newValue) => {
    setYearRange(newValue);
  };

  const handleHeadsRangeChange = (newValue) => {
    setHeadsRange(newValue);
  };

  const handleCabinRangeChange = (newValue) => {
    setCabinRange(newValue);
  };

  const handlePriceRangeChange = (newValue) => {
    setPriceRange(newValue);
  };

  function toggleSortOrder() {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }
  const boxStyle = {
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    backgroundColor: '#EBECEC',
    height: '50px',
    marginTop: '-2px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 'bold'
  };

  function toggleSortOrderByYear() {
    setSortOrderByYear(sortOrderByYear === 'asc' ? 'desc' : 'asc');
  }

  const startDate = dateRange[0];
  const endDate = dateRange[1];

  const [boatTypeError, setBoatTypeError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const handleSearch = () => {
    let errorExists = false;
    resetFilters()


    if (selectedBoatType.length === 0) {
      setBoatTypeError(true);
      errorExists = true;
    } else {
      setBoatTypeError(false);
    }

    if (startDate == null || endDate == null) {
      setDateError(true);
      errorExists = true;
    } else {
      setDateError(false); // And this as well
    }

    if (!errorExists) {
      getData(); // Your search function
    }
  };

  const [bookingData, setBookingData] = useState([]);

  useEffect(() => {
    const fetchBookingData = async () => {
      try {

        const response = await axios.get(`/bookings/${email}`);


        setBookingData(response.data); // Assuming the response data is the array of bookings
        console.log(response.data)
      } catch (error) {
        console.error("Error fetching booking data:", error);
        // Handle the error appropriately
      }
    };

    if (email) {
      fetchBookingData();
    }
  }, [email]);



  const [freeStatsData, setFreeStatsData] = useState([]);
  const [chartData, setChartData] = useState({
    xAxis: [],
    series: [],
  });
  useEffect(() => {
    const fetchFreeStatsData = async () => {
      try {
        const response = await axios.get('/getFreeStats');
        const { dates, values } = response.data;

        const dateObjects = dates.map(date => new Date(date));
        setChartData({
          series: [{ data: values, area: true }],
        });
        console.log("xAXis: ", chartData.xAxis);
        console.log("series: ", chartData.series);

      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    };

    fetchFreeStatsData();
  }, []);


  console.log(freeStatsData)
  const isDatePastOrToday = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(dateString);
    return date <= today;
  };

  const totalka = (totalBoats) => {

    setTotalBoats(totalBoats);


  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  const isSupportedLanguage = currentLanguage.startsWith('sk') || currentLanguage.startsWith('cs');

  return (

    <div className="App">




      <Router>
        <header className="App-header" style={{ backgroundColor: 'white' }}>
          <Routes>

            <Route path="/" element={<Navbar email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />

          </Routes>



          <Routes>
            <Route path="/" element={

              <Box sx={{ backgroundColor: '#2970B8', borderBottom: 1, borderColor: '#c8ecff', }}>


                <Grid
                  container
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    padding: 3,
                    paddingTop: 6,
                    height: "15hw",



                  }}


                >

                  <CountrySelect selectedCountries={selectedCountries} setSelectedCountries={setSelectedCountries} />

                </Grid>
              </Box>
            } />

          </Routes>


        </header>
        <Routes>

          <Route path="/" element={

            <Box>

              {profileData && profileData.length > 0 || isLoading ? (
                console.log()

              ) : bookingData.length > 0 ? (
                <>
                  <br></br>
                  <br></br>
                  <Typography sx={{ color: "#1D3A58 !important" }}>
                    <DirectionsBoatFilledTwoToneIcon sx={{ fontSize: 50, color: "#4898f7 !important" }} />
                    <h1>{t("Your Bookings")}</h1>
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3, marginTop: 1, marginLeft: 1, paddingTop: 5, paddingBottom: 5 }}>


                    {bookingData
                      .sort((a, b) => new Date(b.periodFrom) - new Date(a.periodFrom))
                      .map((booking, index) => {

                        return (
                          <Card key={index} sx={{ display: 'flex', minWidth: { xs: '99%', sm: '500px', md: '500px' }, minHeight: { xs: '99%', sm: '300px', md: '300px' }, maxWidth: 690 }}>

                            <Box sx={{
                              width: '50%',
                              backgroundImage: `url(${booking.imageUrl})`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              filter: isDatePastOrToday(booking.periodTo) ? 'grayscale(100%)' : 'none'
                            }}>

                            </Box>
                            <CardContent sx={{ width: '50%' }}>
                              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                <Typography sx={{ color: "#FC6D2B !important" }}>
                                  #{booking.reservation_id}
                                </Typography>
                              </Box>
                              <Link href={`/DetailsNoDate/${booking.boat_id}/`} target="_blank" rel="noopener noreferrer" variant="body2">
                                <Box sx={{ display: 'flex', justifyContent: 'start', color: "#1D3A58 !important" }}>
                                  <Typography gutterBottom variant="h5" component="div">
                                    {booking.title}

                                  </Typography>
                                </Box>
                              </Link>
                              <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                <FmdGoodIcon sx={{ color: "#4898f7 !important", fontSize: '17px' }} />
                                <Typography sx={{ color: "#1D3A58 !important", fontSize: '12px' }}>

                                  {booking.locationName}

                                </Typography>

                              </Box>
                              <br></br>
                              <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                <DirectionsBoatFilledTwoToneIcon sx={{ color: "#4898f7 !important", fontSize: '17px', marginRight: 1 }} />
                                <Typography sx={{ color: "#1D3A58 !important", fontSize: '12px' }}>

                                  {booking.modelName}

                                </Typography>

                              </Box>

                              <Box sx={{ display: 'flex', justifyContent: 'start', marginTop: 1 }}>
                                <CalendarIcon sx={{ color: "#4898f7 !important", fontSize: '17px', marginRight: 1 }} />
                                <Typography sx={{ color: "#1D3A58 !important", fontSize: '12px' }}>

                                  {booking.periodFrom} - {booking.periodTo}

                                </Typography>

                              </Box>

                              <Box sx={{ display: 'flex', justifyContent: 'start', marginTop: 1 }}>
                                <EuroSymbolTwoToneIcon sx={{ color: "#1F8AF9 !important", fontSize: '17px', marginRight: 1 }} />
                                <Typography sx={{ color: "#1D3A58 !important", fontSize: '12px' }}>

                                  {booking.priceClient}

                                </Typography>



                              </Box>

                              <Box sx={{ display: 'flex', justifyContent: 'start', marginTop: 1 }}>
                                <h5>{t("Payment plan online")}</h5>
                              </Box>
                              <Box sx={{ display: 'flex', justifyContent: 'start', marginTop: 1 }}>
                                <TableContainer>
                                  <Table sx={{ minWidth: 400 }} aria-label="simple table">
                                    <TableBody>
                                      <Typography sx={{ color: "#1D3A58 !important", fontSize: '12px' }}>
                                        {booking.paymentPlans.map((plan, index) => (

                                          <TableRow key={index} style={plan.payed ? { textDecoration: 'line-through' } : {}}>

                                            <CalendarMonthTwoToneIcon sx={{ color: "#1F8AF9 !important", fontSize: '17px', marginRight: 1 }} /> {plan.deadline}


                                            <EuroSymbolTwoToneIcon sx={{ color: "#1F8AF9 !important", fontSize: '13px', marginRight: 1, marginLeft: 2 }} /> {plan.amount}

                                          </TableRow>



                                        ))}
                                      </Typography>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Box>
                              <br></br><br></br>
                              <Box>
                                <Button variant="contained" sx={{ width: '100%', bottom: 0, right: 0 }} onClick={() => handleOpen(booking)}>{t("Payment")}</Button>

                              </Box>

                            </CardContent>
                          </Card>
                        );
                      })}
                  </Box>
                </>

              ) : null}
            </Box>

          } />

        </Routes>
        <Routes>
          <Route path="/details/:boat_id/:start_date/:end_date" element={
            <NavbarDetail
              email={email}
              userNameCookie={userNameCookie}
              userPictureCookie={userPictureCookie}
            />
          } />
        </Routes>
        <Routes>
          <Route path="/detailsNoDate/:boat_id/" element={<NavbarDetail email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />
        </Routes>
        <Routes>
          <Route path="/country/:country" element={<NavbarDetail email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />
        </Routes>
        <Routes>
          <Route path="/resume/" element={<NavbarDetail email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />
        </Routes>
        <Routes>
          <Route path="/Thewall/" element={<NavbarDetail email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />
        </Routes>
        <Routes>
          <Route path="/bareboat/" element={<NavbarDetail email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />
        </Routes>
        <Routes>
          <Route path="/profile/:user_id" element={<NavbarDetail email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />
        </Routes>
        <Routes>
          <Route path="/Favoritesweb/" element={<NavbarDetail email={email} userNameCookie={userNameCookie} userPictureCookie={userPictureCookie} />} />
        </Routes>
        <Routes>

          <Route path="/details/:boat_id/:start_date/:end_date" element={<BoatDetails email={email} />} />

        </Routes>
        <Routes>

          <Route path="/detailsNoDate/:boat_id/" element={<BoatDetailsNoDate email={email} />} />

        </Routes>
        <Routes>
          <Route path="/country/:country" element={<Country email={email} />} />
        </Routes>
        <Routes>
          <Route path="/resume/" element={<Resume email={email} />} />
        </Routes>
        <Routes>
          <Route path="/Thewall/" element={<Thewall email={email} />} />
        </Routes>
        <Routes>
          <Route path="/bareboat/" element={<Bareboat email={email} />} />
        </Routes>
        <Routes>
          <Route path="/profile/:user_id" element={<Profile email={email} />} />
        </Routes>
        <Routes>
          <Route path="/Favoritesweb/" element={<Favoritesweb email={email} />} />
        </Routes>


        <br></br>
        {isLoading ? (
          <div style={{ position: 'relative', display: 'inline-block', height: '150px', left: '-40px' }}>
            <CircularProgress
              style={{
                position: 'absolute',

                zIndex: 1
              }}
              size={68}
            />
            <SailingTwoToneIcon
              color="info"
              style={{

                position: 'absolute',
                left: 13,
                top: 10,
                fontSize: 40,

                zIndex: 2
              }}
            />
          </div>
        ) : (console.log("naloadovane"))}


        <br></br>

        {profileData && profileData.length > 0 && (









          <Grid container spacing={3} style={{ position: 'relative', marginRight: 0, justifyContent: 'center' }}>


            <Hidden mdDown>



              <Grid item xs={12} sm={12} md={5} lg={5} xl={4} style={{ position: 'relative', marginRight: 0, justifyContent: 'end', minWidth: 200, padding: 60, maxWidth: 500 }}>


                {isLoading ? (
                  console.log()
                ) : (


                  <Stack style={{ marginTop: -10 }}>
                    <Box sx={{ border: 1, borderRadius: "2px", borderColor: "#E4E5E5" }}>
                      <div style={boxStyle}>
                        <p style={{ marginTop: '10px', color: "#5C5C5C" }}>Filter <span class='span-color' > {dajLicePocet()} </span> {t("boats!")} </p>
                      </div>


                      <Box sx={{ padding: 3, backgroundColor: "#fafbfc" }}>
                        <RangePrice value={priceRange} onChange={handlePriceRangeChange} minPrice={minPrice} maxPrice={maxPrice} minPriceInit={minPriceInit} maxPriceInit={maxPriceInit} /><br></br>
                        <RangeCabins value={cabinRange} onChange={handleCabinRangeChange} minCabin={minCabin} maxCabin={maxCabin} minCabinInit={minCabinInit} maxCabinInit={maxCabinInit} /><br></br>
                        <RangeLength value={lengthRange} onChange={handleLengthRangeChange} minLength={minLength} maxLength={maxLength} minLengthInit={minLengthInit} maxLengthInit={maxLengthInit} /><br></br>
                        <RangePerson value={personRange} onChange={handlePersonRangeChange} minPerson={minPerson} maxPerson={maxPerson} minPersonInit={minPersonInit} maxPersonInit={maxPersonInit} /><br></br>
                        <RangeYear value={yearRange} onChange={handleYearRangeChange} minYear={minYear} maxYear={maxYear} minYearInit={minYearInit} maxYearInit={maxYearInit} /><br></br>
                        <RangeHeads value={headsRange} onChange={handleHeadsRangeChange} minHeads={minHeads} maxHeads={maxHeads} minHeadsInit={minHeadsInit} maxHeadsInit={maxHeadsInit} /><br></br><br></br>
                        <ManufacturerSelect selectedManufacturer={selectedManufacturer} setSelectedManufacturer={setSelectedManufacturer} /><br></br>
                        <CompanySelect selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany} /><br></br>
                        <EquipSelect selectedEquip={selectedEquip} setSelectedEquip={setSelectedEquip} /><br></br>
                        <SailTypeSelect selectedSail={selectedSail} setSelectedSail={setSelectedSail} /><br></br>
                        <Box sx={{ display: 'flex' }}>
                          <FormControlLabel
                            control={<Checkbox checked={filterTypes.BAREBOAT} onChange={handleCheckboxChange} name="BAREBOAT" />}
                            label={t("Bareboat")}
                          />

                          <FormControlLabel
                            control={<Checkbox checked={filterTypes.CREWED} onChange={handleCheckboxChange} name="CREWED" />}
                            label={t("Crewed")}
                          />

                        </Box>
                        <br></br><br></br>
                        <Button id="cSlider" color="info" variant="outlined" onClick={resetFilters}>{t("reset filters")} </Button>
                      </Box>
                    </Box>


                  </Stack>




                )}

              </Grid>
            </Hidden>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ marginLeft: { md: '70px', lg: '70px', maxWidth: 700 } }} style={{ marginTop: -29, position: 'relative' }}>
              {isLoading ? (

                console.log()
              ) : (
                <Box style={{ display: 'flex' }}>


                  <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                    <Button color="info" variant="text" onClick={() => { setSortBy('price'); toggleSortOrder(); }}>
                      <ImportExportIcon /><p> {t("Price")}</p> {sortOrder === 'asc'}

                    </Button>
                    <Button color="info" variant="text" onClick={() => { setSortBy('year'); toggleSortOrderByYear(); }} >
                      <ImportExportIcon /> <p>{t("Year")}</p> {sortOrderByYear === 'asc'}
                    </Button>

                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center', // Add this to center content vertically inside the Box
                      position: 'fixed', // Keeps the box in the same place relative to the viewport
                      bottom: 40, // Distance from the bottom of the viewport
                      right: 40, // Distance from the right of the viewport
                      zIndex: 1000 // Ensures the box stays on top of other content (adjust as needed)
                    }}>
                      {['left'].map((anchor) => (
                        <React.Fragment key={anchor}>

                          <Hidden mdUp>
                            <Badge badgeContent={dajLicePocet()} max={9999} color="secondary">
                              <Avatar sx={{ bgcolor: 'warning', width: 50, height: 50 }}>

                                <Button size="small" color="info" onClick={toggleDrawer(anchor, true)}><FilterAltRoundedIcon sx={{ fontSize: 40, color: "#fff" }} />{t("")} </Button>

                              </Avatar>
                            </Badge>
                          </Hidden>
                          <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                          >
                            {list(anchor)}
                          </Drawer>
                        </React.Fragment>

                      ))}
                    </Box>
                  </Stack>
                </Box>
              )}



              {console.log("TUTO: " + getSortedData().length)}

              {isLoading ? (
                Array.from(new Array(1)).map((_, index) => (
                  console.log("loadujem")
                ))

              ) : (

                getSortedData().slice(0, displayedItems).map((data, index) => (


                  <Stack spacing={1} >

                    <BoatCard key={index} boatData={data} startDate={startDate} endDate={endDate} email={email} />

                  </Stack>
                ))
              )}

              {profileData && displayedItems < profileData.length && isLoading ? (



                console.log()

              ) : (
                dajLicePocet() > displayedItems && (
                  <Button color="info" onClick={() => setDisplayedItems(prev => prev + 10)} variant="outlined"> {t("Load More Boats")}
                  </Button>
                )

              )}


            </Grid>
          </Grid>
        )}
        {console.log(dajLicePocet())}
        {console.log(displayedItems)}
        {/*
        <Routes>
          
          <Route path="/" element={
            <Box>
              {profileData && displayedItems && profileData.length > 0 ? (



                console.log()

              ) : (
                <Box style={{ marginTop: -50, justifyContent: 'center', display: 'flex' }}>
                  <ImageList sx={{ width: '90%' }} cols={isMobile ? 1 : 5} variant="masonry">
                    {itemData.map((item) => (
                      <ImageListItem key={item.img} sx={{ width: isMobile ? '95%' : 'auto' }}>
                        <img
                          srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item.img}?w=248&fit=crop&auto=format`}
                          alt={item.title}
                          loading="lazy"
                          style={{ width: '100%', height: 'auto' }}  // Ensures images take full width of the container
                        />
                        <ImageListItemBar
                          title={item.title}
                          actionIcon={
                            <IconButton
                              sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                              aria-label={`info about ${item.title}`}
                            >
               
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>

                </Box>


              )}
            </Box>
          } />

        

        </Routes>
        */}

        {profileData && profileData.length > 0 || isLoading ? (



          console.log()

        ) : (

          <Grid>



            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <Routes>

                <Route path="/" element={<LandingPage />} />

              </Routes>
            </div>

            <br></br><br></br>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Routes>

                <Route path="/" element={<Mansonry />} />

              </Routes>
            </div>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '70px' }}>
 
      {/* *
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '70px' }}>
        
        <Routes>
          {isSupportedLanguage && (
            <Route path="/" element={<BlogWidget />} />
          )}
        </Routes>
        
      </div>
      */}
            </div>

            {/*
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

<Routes>

  <Route path="/" element={<LastView />} />

</Routes>
</div>

<br></br><br></br>
*/}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 100 }}>
              <Routes >

                <Route path="/" element={<CharterGrid />} />

              </Routes>
            </div>

            <Routes>
              <Route path="/" element={

                <div>
                  <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ marginBottom: 100 }}>
                    <Grid item xs={12} md={6}>
                      <Box sx={{ width: "100%" }}> {/* Adjust width to 100% to use full width of the Grid item */}
                        <Typography sx={{ color: "#1D3A58 !important" }}>
                          <SailingTwoToneIcon sx={{ fontSize: 60, color: "#4898f7 !important" }}></SailingTwoToneIcon>
                          <h1>{t("Free yachts in Crotia for the summer of 2025")}</h1>
                          <h3>{t("Free yachts in Croatia for the summer of 2025 are decreasing week by week. Hurry up to book your vacation!!")}</h3>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                        <LineChart
                          xAxis={[{
                            data:
                              [new Date(2025, 6, 0),
                              new Date(2025, 6, 5),
                              new Date(2025, 6, 12),
                              new Date(2025, 6, 19),
                              new Date(2025, 6, 26),
                              new Date(2025, 7, 2),
                              new Date(2025, 7, 9),
                              new Date(2025, 7, 16),
                              new Date(2025, 7, 23),
                              new Date(2025, 7, 30),
                              new Date(2025, 8, 6),
                              new Date(2025, 8, 13),
                              new Date(2025, 8, 20),
                              new Date(2025, 8, 27)],
                            scaleType: 'time',

                          }]}

                          


                          series={[
                            {
                              data: [3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000, 3000],
                              area: true,
                              label: t('All yachts'),
                              
                            },
                            ...chartData.series.map(series => ({ ...series, label: series.label || t('Free yachts') })),
                          ]}

                          
                          width={600}
                          height={300}
      
                         
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </div>

              } />
              {/* Other routes */}
            </Routes>

            <div>

              <Routes>

                <Route path="/" element={<Footer />} />

              </Routes>
            </div>

            <div>
              <Routes>

                <Route path="/details/:boat_id/:start_date/:end_date" element={<Footer />} />

              </Routes>
            </div>


          </Grid>

        )}
      </Router>
      <div>
        <ToastContainer />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("Payment plan online")}
          </Typography>
          <TableContainer >
            <Table aria-label="payment plan table" sx={{ fontSize: '10px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Due to date")}</TableCell>
                  <TableCell>{t("Amount")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <Hidden mdDown>
                    <TableCell>{t("IBAN")}</TableCell>
                    <TableCell>{t("VS")}</TableCell>

                  </Hidden>
                  <TableCell>{t("Card Payment")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {selectedBooking && selectedBooking.paymentPlans.map((plan, index) => (
                  <TableRow key={index} sx={plan.payed ? { textDecoration: 'line-through' } : null}>
                    <TableCell>
                      {plan.deadline}
                    </TableCell>
                    <TableCell>
                      {plan.amount} eur
                    </TableCell>
                    <TableCell>
                      {plan.payed ? t('Paid') : t('Pending')}
                    </TableCell>
                    <Hidden mdDown>
                      <TableCell>
                        IBAN: SK57 7500 0000 0040 3281 2626
                      </TableCell>
                      <TableCell>
                        VS: {selectedBooking && selectedBooking.reservation_id}
                      </TableCell>
                    </Hidden>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!plan.link_card_payments || plan.payed} // Disable if no link or already paid
                        onClick={() => window.open(plan.link_card_payments, '_blank')} // Open link in new tab
                      >
                        <CreditCardIcon style={{ marginRight: 5 }} /> {t('Pay with Card')}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Hidden mdUp>
            <Box >
              <Typography>
                <h4>
                  Bank transfer details
                </h4>
              </Typography>
              <Typography>
                IBAN: SK57 7500 0000 0040 3281 2626
              </Typography>
              <Typography>
                VS: {selectedBooking && selectedBooking.reservation_id}
              </Typography>

            </Box>
          </Hidden>


        </Box>
      </Modal >

    </div >

  );
}

const itemData = [
  {
    img: 'images/croatia.png',
    title: 'Croatia',
    amount: 3000


  },
  {
    img: 'images/greece.png',
    title: 'Greece',
    amount: 3000


  },
  {
    img: 'images/spain.png',
    title: 'Spain',
    amount: 3000

  },
  {
    img: 'images/italy.png',
    title: 'Italy',


  },
  {
    img: 'images/france.png',
    title: 'France',


  },
  {
    img: 'images/thailand.png',
    title: 'Thailand',


  },
  {
    img: 'images/norway.png',
    title: 'Norway',


  },
  {
    img: 'images/britishvirginislands.png',
    title: 'British virgin islands',


  },
  {
    img: 'images/seycheless.png',
    title: 'Seychelless',


  },

  {
    img: 'images/slovenia.png',
    title: 'Slovenia',


  },
]

export default App;